const contractConfig = {
  lpManagerAddr: "0x92750EfD93088A7C6E1Fc6289548f12a0ff95747",
  lpManagerABI: `[
        {
            "inputs":[
                {
                    "internalType":"address",
                    "name":"token",
                    "type":"address"
                  }
              ],
              "name":"getPoolBalanceViaToken",
              "outputs":[
                  {
                      "internalType":"uint256",
                      "name":"balance",
                      "type":"uint256"
                  }
              ],
              "stateMutability":"view",
              "type":"function"
          },
          {
              "inputs":[

              ],
              "name":"getEthPoolBalance",
              "outputs":[
                  {
                      "internalType":"uint256",
                      "name":"balance",
                      "type":"uint256"
                  }
              ],
              "stateMutability":"view",
              "type":"function"
          }
      ]`,
  liquidityTokenList: [
    { name: "USDC", address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174" },
    { name: "USDT", address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F" },
  //  { name: "ETH", address: "0x0000000000000000000000000000000000000000" },
  ],
};

export default contractConfig;

