import React from "react";

import { ManagementLogin } from "../../components";
import "./styles/login.scss";

const Login = () => {
  return (
    <div className="managementLoginWrapper">
      <ManagementLogin />
    </div>
  );
};

export default Login;
